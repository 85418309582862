import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import parse from 'html-react-parser';

import { Container } from '@material-ui/core';

import SEO from '../components/layout/seo';
import PageHeader from '../ui/PageHeader';
import Content from '../ui/Content';

const Sobre = () => {
  const { datoCmsAbout } = useStaticQuery(graphql`
    {
      datoCmsAbout {
        contentNode {
          childMarkdownRemark {
            html
          }
        }
        featuredImage {
          title
          alt
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO title="Alphanorth. Quem somos" />
      <Container>
        <PageHeader
          maxWidth="sm"
          title="Sobre"
          lead="Nosso trabalho é dedicar parte do nosso tempo a representação coletiva ou individual dos Associados. Buscamos encaminhar e resolver os problemas relacionados com a instalação e funcionamento das nossas empresas, terrenos, comércio e bem comum."
        />
        <Content>
          <Img
            fluid={datoCmsAbout.featuredImage.fluid}
            alt={datoCmsAbout.featuredImage.alt}
          />

          {parse(datoCmsAbout.contentNode.childMarkdownRemark.html)}
        </Content>
      </Container>
    </div>
  );
};

export default Sobre;
