import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContentStyled = styled.main`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`};
    margin: 1.5rem auto 0.5rem;
    font-family: ${({ theme }) => theme.typography.headingFont};
  }

  p {
    max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`};
    margin: 0 auto 1rem;
    font-size: ${({ theme }) => `${theme.typography.fontSize}px`};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    line-height: 1.5;

    &:last-of-type {
      margin-bottom: 5rem;
    }
  }

  a {
    color: green;
    transition: ${({ theme }) =>
      `color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`};

    &:hover,
    &:visited {
      color: blue;
    }
  }

  .gatsby-image-wrapper {
    max-height: 55vh;
    object-fit: cover;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const Content = ({ children }) => <ContentStyled>{children}</ContentStyled>;

Content.propTypes = {
  children: PropTypes.node,
};

export default Content;
